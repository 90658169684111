.hubspot-wrapper {
  max-width: 60%;
}
@media (max-width: 1000px) {
  .hubspot-wrapper {
    max-width: 90%;
  }
}

.contact-form-wrapper {
  background: black;
  padding: 5em 0;
}
